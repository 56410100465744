@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100..900;1,100..900&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #fbfbfb !important;
  font-family: "Public Sans", sans-serif !important;
}

.sign-banner-part {
  display: flex;
  align-items: center;
}

.left-banner-img {
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #2B3041;
  position: relative;
}

.logo {
  position: absolute;
  top: 50%;
  z-index: 1;
  background: #2B3041;
  padding: 10px;
  border-radius: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo img {
  width: 320px;
}

.login-box-inner-wrap {
  max-width: 550px;
  margin: auto;
  padding: 45px;
  min-height: 450px;
}

.login-box-inner-wrap h2 {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.6px;
}

.login-box-inner-wrap p {
  font-size: 15px;
  font-weight: 400;
  color: #6E6B78;
  font-family: "Public Sans", sans-serif;
}

.login-box-inner-wrap form {
  margin-top: 35px;
}

.login-box-inner-wrap label {
  color: #444151;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 6px;
  height: 42px;
}

.login-box-inner-wrap input:focus {
  box-shadow: none;
  border-color: #D1D0D4;
}

.form-select:focus {
  border-color: #dee2e6 !important;
  outline: 0;
  box-shadow: none !important;
}

.login-box-inner-wrap input::placeholder {
  color: #ACAAB1;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
  letter-spacing: 0.3px;
}

.pass-rember-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remember-check {
  display: flex;
  align-items: center;
  gap: 0px;
}

.remember-check input {
  height: 16px;
  width: 16px;
}

.login-box-inner-wrap input {
  background: #fff;
  border-radius: 7px;
  height: 42px;
}

/* .login-box-inner-wrap label {
  color: #000;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  margin-bottom: 0;
} */

.forgot {
  color: #DD2180;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  text-decoration: none;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.3px;
  display: block;
}

.submit {
  margin-top: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  height: 42px;
  text-decoration: none;
  margin: auto;
  border-radius: 7px;
  background: #DD2180;
  box-shadow: 0px 2px 6px 0px rgb(221 33 128 / 53%);
  border: 0;
}

.otp-banner-img {
  background-image: url("../Images/otp-banner.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.forgot-btn {
  margin-top: 30px !important;
}

.back-link-ad {
  text-align: center;
  margin-top: 20px;
}

.back-link-ad a {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  gap: 6px;
  color: #DD2180;
  text-decoration: none;
}

.back-link-ad a svg path {
  stroke: #DD2180;
}

.reset-banner-img {
  background-image: url("../Images/reset-pass.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.error-banner-img {
  background-color: #2B3041;
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.error-text-box {
  max-width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 90px;
}

.error-text-box h2 {
  font-size: 96px;
  font-weight: 500;
  line-height: 96px;
}

.error-text-box>div {
  /* Basic Typography/h4 */
  font-family: "Public Sans";
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  /* 158.333% */
}

.error-text-box p {
  font-size: 15px;
  font-weight: 400;
  margin-top: 11px;
  color: #ffffff8a;
}

.error-text-box {
  padding-top: 35px;
  color: #fff;
}

.error-text-box a {
  background: #DD2180;
  box-shadow: 0px 2px 6px 0px rgb(221 33 128 / 53%);
  padding: 10px 15px;
  color: #fff;
  border-radius: 8px;
  text-decoration: none;
  display: block;
  max-width: 140px;
  margin: auto;
}

.maintenance-banner-img {
  background-image: url("../Images/under-maintenance.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 30%;
  position: relative;
}

.resend-mail p {
  text-align: center;
  margin-top: 20px;
}

.resend-mail p a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-decoration: none;
  color: #DD2180;
}

.login-box-inner-wrap p span {
  font-size: 15px;
  color: #444151;
  font-weight: 400;
}

.new-otp-banner-img {
  background-image: url("../Images/banner-otp.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}


/* dashboard page css */

.main-wrap {
  display: flex;
  height: 100vh;
}

aside {
  background: #2B3041;
  width: 270px;
  height: 100vh;
  position: fixed;
  left: 0;
  z-index: 1;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
}

.toggle-icon {
  position: fixed;
  left: 245px;
  border: 2px solid #fff;
  padding: 5px 6px;
  background: #071523;
  filter: drop-shadow(0px 6px 10px rgba(151, 151, 151, 0.47));
  width: 40px;
  height: 40px;
  top: 70px;
  display: flex;
  justify-content: center;
  z-index: 1;
  border-radius: 62px;
  align-items: center;
  cursor: pointer;
}

.toggle-icon img {
  width: 20px;
  filter: invert(1);
}

.logo-side img {
  min-width: 200px;
  padding: 25px 30px;
  width: 100%;
  box-sizing: border-box;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - 170px);
}

.side-menu .side-btm-space {
  margin-bottom: 5px;
}

.side-menu a.bar_active {
  color: #ffffff;
  border-radius: 6px;
  box-shadow: 0px 2px 6px 0px rgb(221 33 128 / 53%);
  margin: 0px 10px;
  padding: 12px 15px;
  background: #DD2180;
}

.side-menu a {
  color: #ffffff;
  display: flex !important;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  padding: 10px 25px;
  font-weight: 400 !important;
  line-height: normal;
  text-decoration: none;
  display: block;
}

.side-menu a.bar_active {
  color: #ffffff;
}

.side-menu a {
  color: #ffffff;
  font-family: "Public Sans", sans-serif;
  font-size: 15px;
  font-weight: 400 !important;
  line-height: normal;
  align-items: center;
}

a.menu-btn.bar_active path {
  stroke: #fff;
}

.side-menu a svg {
  margin-right: 13px;
  width: 22px;
}

.side-menu a p {
  margin: 0;
}

.right-side-cmn {
  width: calc(100% - 270px);
  position: relative;
  margin-left: auto;
  background: #fbfbfb;
  height: 100vh;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.right-top-bar {
  border-radius: var(--Border-Radius-border-radius-md, 6px);
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 4px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.left-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-side-bar input {
  border: 0 !important;
  background-color: transparent;
  outline: none !important;
}

.left-side-bar input::placeholder {
  color: #ACAAB1;
  font-size: 15px;
  font-weight: 400;

}

.right-side-bar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.right-top-bar .user-img-top img {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

.dashboard-items {
  margin: 30px 0 10px 10px;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
}


.dashbox-inner-wrap select.form-select {
  border: 1px solid #b9b5ab;
  background: #fff;
  color: #000;
  font-family: "Public Sans", sans-serif;
  height: 40px;
  width: 200px;
  padding-left: 20px;
  font-size: 14px;
  font-weight: 400;

  line-height: 24px;
  /* 160% */
  letter-spacing: -0.3px;
  border-radius: 0;
  background-image: url("../Images/Expand_down.svg");
  background-repeat: no-repeat;
  background-position: center right 10px;
}

.dash-inner-boxes {
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  padding: 25px 22px;
  margin-top: 10px;
  border-radius: 6px;
}

.session {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
}

.left-session p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #444151;
  margin-bottom: 5px;
}

.left-session h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 38px;
  color: #444151;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.left-session h2 span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #28C76F;
}

span.total-users {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  color: #6E6B78;
}

.filters {
  margin-left: 10px;
  background: #fff;
  box-shadow: 0px 3px 12px 0px rgb(0 0 0 / 15%);
  border-radius: 6px;
  margin-top: 20px;
}

.user-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 22px;
}

.search-tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
}

.export-box p {
  margin: 0px;
  display: flex;
  gap: 9px;
  align-items: center;
  color: #808390;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.export-box {
  background: #EAEBED;
  padding: 8px 17px;
  border-radius: 6px;
  cursor: pointer;
}

.input-search input {
  background: #fff;
  border-radius: 6px;
  width: 271px;
  border: 1px solid #D1D0D4;
  font-size: 15px;
  height: 39px;
  font-weight: 400;
}

.input-search input::placeholder {
  color: #D1D0D4;
}

input#formBasicPassword {
  box-shadow: none;
  border: 1px solid #D1D0D4;
}

.select-box {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 70px;
}

.table-responsive {
  border-top: 1px solid #E6E5E7;
}

.table-responsive thead tr th {
  padding: 14px 10px;
  font-size: 13px;
  color: #444151;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
}

.user-checkbox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile img {
  height: 34px;
  width: 34px;
}

.first-user {
  display: flex;
  align-items: center;
  gap: 20px;
}

.user-profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.user-id p {
  font-size: 15px;
  color: #444151;
  font-weight: 500;
  margin: 0px;
}

.user-id span {
  font-size: 13px;
  color: #6E6B78;
  font-weight: 400;
  margin: 0px;
}

.table-responsive tbody tr td {
  padding: 5px 10px;
  vertical-align: middle;
}

.user-role p {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
  color: #444151;
}

.debit {
  font-size: 13px;
  font-weight: 500;
  line-height: 47px;
  color: #6E6B78 !important;
}

.pending p {
  color: #FF9F43;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;
}

.pending {
  background: #FFEFE1;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active {
  background: #DCF6E8;
  text-align: center;
  max-width: 90px;
  padding: 5px;
  border-radius: 6px;
}

.table-responsive td .active p {
  color: #28C76F;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px;

}

.action {
  display: flex;
  gap: 16px;
  align-items: center;
}

.table-responsive thead tr th:before {
  position: absolute;
  content: "";
  background: #E6E5E7;
  width: 2px;
  height: 26px;
  right: 20px;
  top: 11px;
}

.user-role {
  line-height: 47px;
}

.showing-user p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #ACAAB1;
  margin: 0px;
}

.pagination-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.table-responsive table {
  margin: 0px;
}

.pagination-section {
  padding: 10px;
}

.pagination-block a.page-link {
  background: #EFEEF0;
  border-radius: 6px;
  color: #423F4F;
  font-size: 13px;
  font-weight: 400;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
  padding: 0px;
}

.pagination-block ul {
  margin: 0px !important;
  gap: 6px;
}

.pagination-block li.page-item a span {
  font-size: 25px;
}

.pagination-block li.page-item.active .page-link {
  background: #DD2180;
  border: 1px solid #DD2180;
  border-radius: 6px;
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  line-height: 38px;
  justify-content: center;
}

.pagination-block .page-link:focus {
  box-shadow: none !important;
}

.pagination-block li.page-item:hover a {
  background: #E8E6FC;
  color: #DD2180;
}

.made-with {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10px;
  padding: 10px 0;
  margin-top: 23px;
}

.pixinvent p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #6C6976;
}

.pixinvent p span {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #DD2180;
}

.bottom-links a {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin: 0px;
  color: #DD2180;
  text-decoration: none;
}

.bottom-links {
  display: flex;
  align-items: center;
  gap: 16px;
}

.set-otp input {
  width: calc(100% / 6) !important;
  border: 1px solid #D1D0D4;
  outline: none;
  height: 50px !important;
}

.set-otp>div {
  justify-content: space-between !important;
  gap: 10px;
}

.notify-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notify-head p {
  margin: 0;
}

.notify-head>div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.notify-head>div span {
  background-color: #FBE4F0;
  padding: 10px;
  border-radius: 5px;
  color: #DD2180;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 10px;
}

.drop-pro-view {
  display: flex;
  gap: 5px;
  align-items: center;
}

.logout-btn {
  background-color: #ff4c51 !important;
  color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 3px !important;
  margin: 0px 10px !important;
  border-radius: 8px !important;
  width: 179px !important;
  margin-top: 10px !important;
}

.admin-name h3 {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin: 0px;
}

.admin-name p {
  font-size: 13px;
  font-weight: 400;
  color: #ACAAB1;
  line-height: 20px;
  margin: 0px;
}

.my-profile a {
  color: #444151;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 9px;
  align-items: center;
  margin-bottom: 5px;
}

.user-img-top button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 50px;
  border-radius: 50%;
  height: 50px;
}

.notification-icon button#dropdown-basic {
  background: none;
  border: none;
  padding: 0px;
  width: 25px;
  border-radius: 50%;
  height: 25px;
}

.notification-icon .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.user-img-top .dropdown-item:active {
  background: #f8f9fa !important;
  color: #444151 !important;
}

.notify-head p {
  color: #444151;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
}

.user-notfic-icon img {
  width: 40px;
  height: 40px;
}

/* end dashboard */

/* my profile page */
.profile-top-box {
  border-radius: 6px;
  box-shadow: 0px 2px 8px 0px rgba(47, 43, 61, 0.12);
  padding: 18px 18px;
  margin-left: 10px;
  background: #fff;
}

.photo-upload {
  display: flex;
  gap: 25px;
  align-items: center;
}

.btn-up button {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #DD2180;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgb(221 33 128 / 53%);
}

.btn-up {
  display: flex;
  gap: 20px;
  align-items: center;
}

.reset-box p {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #EAEBED;
  padding: 10px 15px;
  border-radius: 6px;
  color: #808390;
  margin: 0px;
}

p.image-disp {
  color: #6E6B78;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 16px;
  margin-bottom: 0px;
}

.input-profile {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
}

.input-field-width {
  width: 50%;
}

.input-field-width input::placeholder {
  color: #ACAAB1;
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #D1D0D4 !important;
}

.profile-disp label {
  margin-bottom: 3px;
}

.heading-profile h2 {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: #444151;
  margin: 0px;
}

.password-req h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  color: #6E6B78;
  margin: 0px;
}

.password-req p {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  color: #6E6B78;
  margin: 0px;
  margin-top: 12px;
  display: flex;
  gap: 8px;
}

.user-verification img {
  background: #DD2180;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.user-verification img:nth-child(2) {
  background: #FF4C51;
  padding: 7px;
  height: 33px;
  width: 33px;
  border-radius: 6px;
}

.cont-num {
  line-height: 47px;
  color: #444151;
}

a.menu-btn.bar_active ellipse {
  stroke: #fff;
}

a.menu-btn ellipse {
  stroke: #fff !important;
}

a.menu-btn rect {
  stroke: #fff !important;

}

a.menu-btn path {
  stroke: #fff;
}

/* end */

input[type=checkbox] {
  position: relative;
  border: 2px solid #ACAAB1;
  border-radius: 2px;
  background: none;
  cursor: pointer;
  line-height: 0;
  margin: 0 .6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  border-radius: 6px;
}


input[type=checkbox]:checked {
  background-color: #dd2180;
  opacity: 1;
  border: 1px solid #dd2180;
}

input[type=checkbox]:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 6px;
  height: 11px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

.side-pages {
  padding: 10px 25px;
}

.side-pages p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #ACAAB1;
}

.pass-eys img {
  position: absolute;
  top: 0px;
}

.pass-eys {
  position: relative;
}

.pass-eys img {
  position: absolute;
  top: 39px;
  right: 19px;
  cursor: pointer;
}

.file-input {
  display: none;
}

.custom-file-upload {
  display: inline-block;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  background: #DD2180;
  padding: 10px 25px;
  border-radius: 6px;
  border: none;
  color: #fff;
  box-shadow: 0px 2px 6px 0px rgb(221 33 128 / 53%);
}

.notification-box h5 {
  color: #343637;
  color: var(--Foundation-Grey-grey-500, #343637);
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.notification-box p {
  word-wrap: break-word;
  color: #777879;
  color: var(--Foundation-Grey-grey-300, #777879);

  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 2px;
  white-space: break-spaces;
  width: 300px;
}

.notification-box span {
  color: #6b6b6b;
  color: var(--Foundation-Grey-grey-300, #6b6b6b);

  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.drop-pro-view {
  align-items: self-start;
  display: flex;
  gap: 12px;
}

.view-all-action {
  display: flex;
  justify-content: center;
  align-items: baseline;
}


.view-all-action button {
  border: 0;
  background: #E659A0;
  padding: 10px;
  line-height: normal;
  width: calc(100% - 40px);
  border-radius: 6px;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px;
  font-weight: 500;
  margin: 5px 0px;
}

/* .view-all-action button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 348px !important;
  height: 30px !important;
  border-radius: 6px !important;
  background: #9675FA !important;
  box-shadow: 0px 2px 6px 0px #DD21804D !important;
  color: #fff !important;
  text-align: center !important;
  font-size: 13px;
  font-weight: 500;
  border: none !important;

} */


.select-box-cmn {
  display: flex;
  justify-content: center;
  width: 160px;
}

.select-box-cmn select {
  color: #d1d0d4;
}


.cmn-head-txt h2 {
  color: var(--Light-Text-Primary, text-primary);
  font-feature-settings: 'liga'off, 'clig'off;
  font-family: "Public Sans";
  font-size: 30px;
  font-weight: 500;
  line-height: 28px;
  /* 93.333% */
}

.cmn-head-txt>div {
  padding: 20px 22px;
}

.cmn-head-txt>div form {
  display: flex;
}

.cmn-head-txt>div form {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 30px;
}

.border-tb {
  border-top: 1px solid rgba(47, 43, 61, 0.12);
  border-bottom: 1px solid rgba(47, 43, 61, 0.12);
}

.border-bottom-set {
  border-bottom: 1px solid rgba(47, 43, 61, 0.12);
}

.user-id a {
  text-decoration: none;
}


.table-cmn-toggle input:focus {
  box-shadow: none !important;
  border-color: #DD2180;
  --bs-form-switch-bg: url("../Images/toggle-white.svg") !important;
}


.table-cmn-toggle .form-switch .form-check-input:checked:focus {
  --bs-form-switch-bg: url("../Images/toggle-white.svg") !important;
}

.table-cmn-toggle .form-switch .form-check-input {
  --bs-form-switch-bg: url("../Images/toggle-grey.svg") !important;
  border-color: #DD2180;
}

.table-cmn-toggle .form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("../Images/toggle-grey.svg") !important;
}


.table-cmn-toggle .form-switch .form-check-input:checked {
  background-color: #DD2180;
  border-color: #DD2180;
  --bs-form-switch-bg: url("../Images/toggle-white.svg") !important;
}


.table-cmn-toggle input {
  background-repeat: no-repeat;
  height: 22px;
  width: 40px !important;
}



.table-cmn-toggle input::before {
  background: none;
  visibility: hidden;
}

.cmn-head-txt ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  gap: 10px;
}


.cmn-head-txt ul li a {
  color: #2F2B3DE5;
  text-align: center;
  font-family: "Public Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-transform: capitalize;
  text-decoration: none;
  padding: 10px 22px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.cmn-head-txt ul li a.active {
  background-color: #DD2180;
  color: #fff !important;
  border-radius: 8px;
}


.cmn-head-txt ul li a.active svg path {
  stroke: #ffffff;
}

.cmn-head-txt ul li a.active svg circle {
  stroke: #ffffff;
}


.cmn-head-txt ul li a svg path {
  stroke: #2F2B3D;
}


.cmn-head-txt ul li a svg circle {
  stroke: #2F2B3D;
}

.profile-disc-set ul {
  list-style: none;
  padding: 0;
}

.profile-disc-set ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
}

.profile-disc-set ul li p {
  margin-bottom: 0;
}


.profile-disc-set ul li div {
  color: #808390;
}


.transaction-tble-price>ul li>div {
  display: flex;
  gap: 15px;
}

.transaction-tble-price>ul li {
  border-radius: 10px;
  background: #F5F2F2;
  padding: 10px 20px;
}

.transaction-tble-price>ul li>div h3 {
  color: #200E32;
  text-align: center;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
}

.transaction-tble-price>ul li>div p {
  color: #DD2180;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.25px;
  margin-bottom: 0;
}

.transaction-tble-price>ul li>div:last-child {
  color: #9A9A9A;
  text-align: center;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.25px;
}

.TBD-set {
  position: relative;
  height: 80vh;
}


.TBD-set p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 27.5% */
}

.fill-icon a.menu-btn path {
  fill: #fff !important;
  stroke: none;
}